(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/oddset-kambi/components/nav-menu/assets/javascripts/oddset-nav-menu.js') >= 0) return;  svs.modules.push('/modules/oddset-kambi/components/nav-menu/assets/javascripts/oddset-nav-menu.js');
'use strict';

const {
  matchPath
} = ReactRouterDOM;
const {
  useContext
} = React;
const {
  NavContext
} = svs.oddset_kambi.components.nav_context;
const {
  BetsContext
} = svs.oddset_kambi.components.bets_context;
const {
  ItemsMenu
} = svs.ui.ReactItemsMenu;
const {
  MenuTabTypes
} = svs.oddset_kambi.components.common.constants;
const {
  OddsetNavItem
} = svs.oddset_kambi.components.nav_menu;
const isSelfService = svs.utils.ns.isNamespace('svs.core.config.data.svsconfig.isSelfService') && svs.core.config.data.svsconfig.isSelfService;
const isSelected = (route, activePathname) => Boolean(matchPath(activePathname, {
  path: "/".concat(route.path)
}));
const isAnySelected = (routes, activePathname) => routes.some(route => matchPath(activePathname, {
  path: "/".concat(route.path)
}));
const OddsetNavMenu = _ref => {
  let {
    playRoute,
    activeRoutes,
    renderMobileMenu
  } = _ref;
  const {
    activePathname,
    currentRoute
  } = useContext(NavContext);
  const {
    numPendingBets
  } = useContext(BetsContext);

  const visibleRoutes = activeRoutes.filter(tab => renderMobileMenu && tab.activeMobile && !isSelfService || !renderMobileMenu && tab.activeDesktop);
  return React.createElement(ItemsMenu, {
    ariaLabel: "Meny f\xF6r Oddset",
    branding: "oddset-tab",
    centered: true,
    className: "js-oddset-items oddset-nav".concat(renderMobileMenu ? ' oddset-nav--mobile' : ''),
    compact: renderMobileMenu
  }, React.createElement(OddsetNavItem, {
    currentRoute: currentRoute,
    isSelected: !isAnySelected(visibleRoutes, activePathname),
    key: playRoute.path,
    renderMobileMenu: renderMobileMenu,
    route: playRoute
  }), visibleRoutes.map(route => React.createElement(OddsetNavItem, {
    badge: route.tabType === MenuTabTypes.MyBets ? numPendingBets : null,
    currentRoute: currentRoute,
    isSelected: isSelected(route, activePathname),
    key: route.path,
    renderMobileMenu: renderMobileMenu,
    route: route
  })));
};
setGlobal('svs.oddset_kambi.components.nav_menu.OddsetNavMenu', OddsetNavMenu);

 })(window);